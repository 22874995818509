/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useMemo, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { GetUfs as RemoteGetUfs } from '~/domain/usecases/externalServices/remote';
import { makeReduxListDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListDiagnosisReportFactory';
import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { LaudoFilterProps } from '~/presentation/pages/Laudo';
import { translator } from '../i18n';
import { Button } from '../UI';
import Input from '../UI/input';
import SearchSelect from '../UI/searchSelect';
import {
  Body,
  Box,
  Container,
  FilterChipsContainer,
  Footer,
  RestoreFilters,
  SectionFilter,
  SectionFilterLabel,
} from './styles/StylesLaudo';
import { Status } from '~/domain/usecases/diagnosisReport/remote';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';

interface FilterLaudoProps {
  handleClose: () => void;
  setFilterData: (data: any) => void;
  filterData: LaudoFilterProps;
  status: Status;
}

const FilterLaudo: React.FC<FilterLaudoProps> = ({
  filterData,
  handleClose,
  setFilterData,
  status,
}) => {
  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);

  const { startDate, endDate, active, ...filters } = filterData;

  const { orgId, orgUnitId, role, orgUnits } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { consultant, professional, specialty } = useSelector(
    (state: iStore) => state,
  );

  const { results: professions } = useSelector(
    (store: iStore) => store.professions,
  );

  const formatSpecialty = useMemo(() => {
    const specialtyFiltered = specialty.results.filter(
      item => item.profession?.id === filterData.profession,
    );

    return specialtyFiltered.map(specialty => ({
      label: specialty.specialty?.name,
      value: specialty.specialty?.id,
    }));
  }, [professions, filterData.profession]);

  const formatProfessional = useMemo(() => {
    const professionalFiltered = professional.results.filter(
      item => item.profession?.id === filterData.profession,
    );

    return professionalFiltered.map(professional => ({
      label: `${professional.user.firstName} ${professional.user.lastName}`,
      value: professional.professional.id,
    }));
  }, [professional, filterData.profession]);

  const formatHealthUnit = useMemo(() => {
    return healthUnits.map(unit => ({
      label: unit.name,
      value: unit.id,
    }));
  }, [healthUnits]);

  const formatUfs = useMemo(() => {
    return ufs.map(uf => ({
      label: uf.sigla,
      value: uf.id,
    }));
  }, [ufs]);

  const formatPatients = useMemo(() => {
    return consultant.results.map(unit => ({
      label: `${unit.user.firstName} ${unit.user.lastName}`,
      value: unit.consultant.id,
    }));
  }, [consultant]);

  const formatProfessions = useMemo(() => {
    return professions.map(profession => ({
      label: profession.profession.name,
      value: profession.profession.id,
    }));
  }, [professions]);

  const handleApplyFilters = () => {
    setFilterData((preState: any) => ({
      ...preState,
      active: true,
    }));

    let formattedStartDate;
    let formattedEndDate;

    if (startDate)
      formattedStartDate = startDate.split('/').reverse().join('-');

    if (endDate) formattedEndDate = endDate.split('/').reverse().join('-');

    const dataToSend = {
      ...filters,
      patient: undefined,
      professional: undefined,
      consultant: filterData.patient ?? undefined,
      timestamp:
        formattedStartDate || formattedEndDate
          ? {
              begin: formattedStartDate !== '' ? formattedStartDate : undefined,
              end: formattedEndDate !== '' ? formattedEndDate : undefined,
            }
          : undefined,
      state: '25',
      status: status === 'DELAYED' ? undefined : [status],
      delayed: status === 'DELAYED' ? true : undefined,
      consultantName:
        filterData.consultantName !== undefined
          ? String(filterData.consultantName)
          : undefined,
      healthUnit: filterData.healthUnit ?? undefined,
      profession: filterData.profession ?? undefined,
      specialty: filterData.specialty !== 0 ? filterData.specialty : undefined,
      specialist: filterData.professional
        ? Number(filterData.professional)
        : undefined,
    };

    makeReduxListDiagnosisReport().list({
      body: {
        filters: dataToSend,
      },
    });
  };

  const handleCleanFilters = () => {
    setFilterData((prevState: any) => {
      const updatedFilters = {
        ...prevState,
        startDate: undefined,
        endDate: undefined,
        state: '25',
        healthUnit: undefined,
        profession: undefined,
        specialty: undefined,
        specialist: undefined,
        active: false,
        patient: undefined,
        professional: undefined,
      };

      makeReduxListDiagnosisReport().list({
        body: {
          filters: {
            textMatch: filters.textMatch !== '' ? filters.textMatch : undefined,
            ...updatedFilters,
            healthUnit: undefined,
            status: status === 'DELAYED' ? undefined : [status],
            delayed: status === 'DELAYED' ? true : undefined,
          },
        },
      });

      handleClose();

      return updatedFilters;
    });
  };

  useEffect(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });

    makeRemoteGetUfs()
      .getUfs({})
      .then(res => {
        setUfs(res);
      })
      .catch(err => {
        console.log('Erro ao buscar UFs: ', err);
      });

    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
      state: '25',
    });
  }, [orgUnits, role]);

  return (
    <Container>
      <Body>
        <SectionFilter>
          <SectionFilterLabel>Período</SectionFilterLabel>
          <FilterChipsContainer>
            <ReactInputMask
              height="40px"
              mask="99/99/9999"
              name="birthdate"
              className="birthdate"
              defaultValue={filterData.startDate}
              onChange={e => {
                setFilterData((preState: any) => ({
                  ...preState,
                  startDate: e.target.value.trim(),
                }));
              }}
            >
              <Input
                gridArea="birthdate"
                id="registerBirthDate"
                placeholder={translator('DD/MM/AAAA')}
              />
            </ReactInputMask>
            <div>Até</div>
            <ReactInputMask
              height="40px"
              mask="99/99/9999"
              name="birthdate"
              className="birthdate"
              defaultValue={filterData.endDate}
              onChange={e => {
                setFilterData((preState: any) => ({
                  ...preState,
                  endDate: e.target.value.trim(),
                }));
              }}
            >
              <Input
                gridArea="birthdate"
                id="registerBirthDate"
                placeholder={translator('DD/MM/AAAA')}
              />
            </ReactInputMask>
          </FilterChipsContainer>
        </SectionFilter>
        <SectionFilter>
          <FilterChipsContainer>
            <Box style={{ width: '30%' }}>
              <SectionFilterLabel>UF</SectionFilterLabel>
              <SearchSelect
                height="40px"
                width="100%"
                isDisabled
                options={formatUfs}
                value={{ label: 'PB', value: 25 }}
                onChange={e => {
                  setFilterData((preState: any) => ({
                    ...preState,
                    city: e.value,
                  }));
                }}
              />
            </Box>
            <Box style={{ width: '70%' }}>
              <SectionFilterLabel>Unidade de saúde</SectionFilterLabel>
              <SearchSelect
                height="40px"
                width="100%"
                options={formatHealthUnit}
                value={formatHealthUnit.find(
                  item => item.value === filterData.healthUnit,
                )}
                onChange={e => {
                  setFilterData((preState: any) => ({
                    ...preState,
                    healthUnit: e.value,
                  }));
                }}
              />
            </Box>
          </FilterChipsContainer>
        </SectionFilter>
        <SectionFilter>
          <FilterChipsContainer>
            <Box>
              <SectionFilterLabel>Paciente</SectionFilterLabel>
              <SearchSelect
                height="40px"
                width="100%"
                options={formatPatients}
                value={formatPatients.find(
                  item => item.value === Number(filterData.patient),
                )}
                onChange={e => {
                  setFilterData((preState: any) => ({
                    ...preState,
                    patient: e.value,
                  }));
                }}
              />
            </Box>
          </FilterChipsContainer>
        </SectionFilter>
        <SectionFilter>
          <FilterChipsContainer>
            <Box style={{ width: '50%' }}>
              <SectionFilterLabel>Profissão</SectionFilterLabel>
              <SearchSelect
                height="40px"
                width="100%"
                options={formatProfessions}
                value={formatProfessions.find(
                  item => item.value === filterData.profession,
                )}
                onChange={e => {
                  setFilterData((preState: any) => ({
                    ...preState,
                    profession: e.value,
                  }));
                }}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <SectionFilterLabel>Especialidade</SectionFilterLabel>
              <SearchSelect
                height="40px"
                width="100%"
                options={formatSpecialty}
                value={formatSpecialty.find(
                  item => item.value === filterData.specialty,
                )}
                onChange={e => {
                  setFilterData((preState: any) => ({
                    ...preState,
                    specialty: e.value,
                  }));
                }}
              />
            </Box>
          </FilterChipsContainer>
        </SectionFilter>
        <SectionFilter>
          <FilterChipsContainer>
            <Box>
              <SectionFilterLabel>Profissional</SectionFilterLabel>
              <SearchSelect
                width="100%"
                height="40px"
                options={formatProfessional}
                value={formatProfessional.find(
                  item => item.value === Number(filterData.professional),
                )}
                onChange={e => {
                  setFilterData((preState: any) => ({
                    ...preState,
                    professional: e.value,
                  }));
                }}
                isDisabled={!filterData.specialty || !filterData.profession}
              />
            </Box>
          </FilterChipsContainer>
        </SectionFilter>
      </Body>
      <Footer>
        <RestoreFilters
          size="medium"
          variant="secundary"
          onClick={handleCleanFilters}
        >
          Restaurar padrões
        </RestoreFilters>
        <Button size="small" onClick={handleApplyFilters}>
          Aplicar filtros
        </Button>
      </Footer>
    </Container>
  );
};

export default FilterLaudo;
