import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Container,
  CheckContent,
  Option,
  CheckedForms,
  ProfessionalCheckedForms,
  InlineForms,
  selectStyles,
} from './styles/StyledResponsible';
import {
  Control,
  DropdownIndicator,
  Option as OptionSelect,
} from '../filter/styles/StyledFilterAppointmentHistory';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { FormData } from './CreateReport';
import _ from 'lodash';
import { makeRemoteGetAllProfession } from '~/main/factories/usecases/profession';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import SearchSelect from '../UI/searchSelect';

interface iResponsible {
  responsible: {
    specialtyChecked: boolean;
    professionalChecked: boolean;
  };
}

interface iProfession {
  docProfName: string;
  id: number;
  name: string;
}

const Responsible = () => {
  const [checked, setChecked] = useState<iResponsible['responsible']>({
    specialtyChecked: true,
    professionalChecked: false,
  });
  const [profession, setProfession] = useState<Partial<iProfession[]>>([]);

  const { results: resultsSpecialty } = useSelector(
    (store: iStore) => store.specialty,
  );

  const { orgId, orgUnits, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const { results: resultsProfessional } = useSelector(
    (store: iStore) => store.professional,
  );

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>();

  const generateProfessionSelectOptions = () => {
    return _.sortBy(
      profession?.flatMap(value => {
        return [
          {
            label: value?.name,
            value: value?.id,
          },
        ];
      }),
      item => item?.label?.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const generateSpecialtySelectOptions = () => {
    if (!watch('profession')) return [];

    return _.sortBy(
      resultsSpecialty?.flatMap(value => {
        if (value?.profession?.id === Number(watch('profession'))) {
          return [
            {
              label: value?.specialty?.name,
              value: value?.specialty?.id,
            },
          ];
        }
        return [];
      }),
      item => item?.label?.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const generateProfessionalSelectOptions = () => {
    return _.sortBy(
      resultsProfessional?.flatMap(value => {
        if (
          value?.specialties?.some(
            specialty => specialty?.id === Number(watch('specialty')),
          )
        ) {
          return [
            {
              label: `${value?.user?.firstName} ${value?.user?.lastName}`,
              value: value?.professional?.id,
            },
          ];
        }
        return [];
      }),
      item => item?.label?.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const professionMultiSelectSelected = useMemo(() => {
    return generateProfessionSelectOptions().find(
      item => item.value === Number(watch('profession')),
    );
  }, [generateProfessionSelectOptions]);

  const specialtyMultiSelectSelected = useMemo(() => {
    if (!watch('specialty')) return [];

    return generateSpecialtySelectOptions().find(
      item => item?.value === Number(watch('specialty')),
    );
  }, [generateSpecialtySelectOptions]);

  const professionalMultiSelectSelected = useMemo(() => {
    if (!watch('profession')) return [];
    if (!watch('specialty')) return [];

    return generateProfessionalSelectOptions().find(
      item => item?.value === Number(watch('profissional')),
    );
  }, [generateProfessionalSelectOptions]);

  useEffect(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    makeRemoteGetAllProfession()
      .getAll({
        limit: 9999,
      })
      .then(res => {
        setProfession(res);
      })
      .catch(err => {
        console.log('##err', err);
      });

    makeReduxGetAllSpecialty().getAll({
      filter: {
        hasProfessional: true,
      },
    });

    makeReduxGetAllProfessionals().getAll({
      filter: {
        org: orgId,
        unit: role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
      },
    });
  }, [role, orgUnits]);

  return (
    <Container>
      <CheckContent>
        <Option checked={checked?.specialtyChecked}>
          <input
            {...(register('specialtySelected') as any)}
            type="radio"
            defaultChecked={watch('specialtySelected')}
            checked={watch('specialtySelected')}
            onChange={e => {
              if (e.target.checked) {
                setChecked({
                  ...checked,
                  specialtyChecked: e.target.checked,
                  professionalChecked: false,
                });
                setValue('professionalSelected', false);
                setValue('specialtySelected', true);
                if (watch('specialtySelected')) {
                  setValue('profissional', undefined);
                }
              }
            }}
          />
          <span>Quero destinar a uma especialidade</span>
        </Option>
        {watch('specialtySelected') && (
          <CheckedForms>
            <SearchSelect
              {...(register('profession') as any)}
              className="select"
              id="profession"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option: OptionSelect,
              }}
              controlShouldRenderValue
              options={generateProfessionSelectOptions()}
              value={professionMultiSelectSelected}
              onChange={(e: { value: string }) => {
                setValue('profession', String(e?.value));
                setValue('specialty', '');
                setValue('profissional', '');
              }}
              placeholder="Selecione"
              label="Profissão"
              error={errors?.profession}
              width="30%"
              required
              message={errors?.profession?.message}
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <SearchSelect
              {...(register('specialty') as any)}
              className="select"
              id="specialty"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option: OptionSelect,
              }}
              controlShouldRenderValue
              options={generateSpecialtySelectOptions()}
              value={specialtyMultiSelectSelected}
              onChange={(e: { value: string }) => {
                setValue('specialty', String(e?.value));
                setValue('profissional', undefined);
              }}
              error={errors?.specialty}
              message={errors?.specialty?.message}
              label="Especialidade"
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              width="70%"
              isValidNewOption={() => false}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />
          </CheckedForms>
        )}
      </CheckContent>

      <CheckContent>
        <Option checked={checked?.professionalChecked}>
          <input
            type="radio"
            checked={watch('professionalSelected')}
            defaultChecked={watch('professionalSelected')}
            {...(register('professionalSelected') as any)}
            onChange={e => {
              if (e.target.checked) {
                setChecked({
                  ...checked,
                  specialtyChecked: false,
                  professionalChecked: e.target.checked,
                });
                setValue('professionalSelected', true);
                setValue('specialtySelected', false);
                setValue('profissional', watch('profissional'));
              }
            }}
          />
          <span>Quero enviar para um profissional específico</span>
        </Option>
        {watch('professionalSelected') && (
          <ProfessionalCheckedForms>
            <InlineForms>
              <SearchSelect
                {...(register('profession') as any)}
                className="select"
                id="profession_professional"
                components={{
                  Control,
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Option: OptionSelect,
                }}
                controlShouldRenderValue
                options={generateProfessionSelectOptions()}
                value={professionMultiSelectSelected}
                onChange={(e: { value: string }) => {
                  setValue('profession', String(e?.value));
                  setValue('specialty', '');
                  setValue('profissional', '');
                }}
                error={errors?.profession}
                message={errors?.profession?.message}
                label="Profissão"
                placeholder="Selecione"
                formatCreateLabel={(label: string) => `Buscar por ${label}`}
                styles={selectStyles()}
                width="30%"
                isValidNewOption={() => false}
                required
                noOptionsMessage={() => 'Nenhum resultado encontrado'}
              />

              <SearchSelect
                {...(register('specialty') as any)}
                className="select"
                id="specialty_professional"
                components={{
                  Control,
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Option: OptionSelect,
                }}
                options={generateSpecialtySelectOptions()}
                value={specialtyMultiSelectSelected}
                onChange={(e: { value: string }) => {
                  setValue('specialty', String(e?.value));
                  setValue('profissional', '');
                }}
                controlShouldRenderValue
                error={errors?.specialty}
                message={errors?.specialty?.message}
                label="Especialidade"
                placeholder="Selecione"
                formatCreateLabel={(label: string) => `Buscar por ${label}`}
                styles={selectStyles()}
                width="70%"
                isValidNewOption={() => false}
                required
                noOptionsMessage={() => 'Nenhum resultado encontrado'}
              />
            </InlineForms>

            <SearchSelect
              {...(register('profissional') as any)}
              className="select"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option: OptionSelect,
              }}
              options={generateProfessionalSelectOptions()}
              value={professionalMultiSelectSelected}
              onChange={(e: { value: string }) => {
                setValue('profissional', String(e?.value));
              }}
              controlShouldRenderValue
              error={errors?.profissional}
              label="Profissional"
              message={errors?.profissional?.message}
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />
          </ProfessionalCheckedForms>
        )}
      </CheckContent>
    </Container>
  );
};

export default Responsible;
